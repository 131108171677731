
$(document).ready(function() {
		setTimeout(function(){
		$('#preloader').fadeOut('slow');
	}, 1000);

	$('.imgLiquid').each(function(){
	    var src = $(this).find('img').attr('src');
	    $(this).css('backgroundImage', 'url('+src+')');
	});

	if (window.innerWidth < 768) {
	    var sectionCien = $(window).height();
		$('.navCien').css({'height': sectionCien});
	}
	//menu
	$(document).on('click', 'button.burger', function(){
		if($(this).hasClass('active')){
			$(this).removeClass('active');
			$('nav').stop().animate({'right':'-150%'},400);
			$('h1').removeClass('menu');
			$('html').css({'overflow-y':'visible'});
		}
		else{
			$(this).addClass('active');
			$('nav').stop().animate({'right':'0%'},400);
			$('h1').addClass('menu');
			$('html').css({'overflow-y':'hidden'});
		}
	});


	//agregados list
	$(document).on('click', '.acordion a.header-acordion', function(){
		
		if($(this).hasClass('active')){
			$(this).removeClass('active');
			$(this).parent().stop().animate({'height':'30'},300);
		}else{
			$('.acordion a.header-acordion').parent().stop().animate({'height':'30'},300);
			$('a.header-acordion').removeClass('active');
			$(this).addClass('active');

			var alto = $(this).siblings().height();
			$(this).parent().stop().animate({'height': alto+30},300);
		}
	});

	//menu list
	$(document).on('click', '.acordion-menu a.header-acordion', function(){
		
		if($(this).hasClass('active')){
			$(this).removeClass('active');
			$(this).parent().stop().animate({'height':'30'},300);
		}else{
			$('.acordion-menu a.header-acordion').parent().stop().animate({'height':'30'},300);
			$('a.header-acordion').removeClass('active');
			$(this).addClass('active');

			var alto = $(this).siblings().height();
			$(this).parent().stop().animate({'height': alto+30},300);
		}
	});

	//menu list
	$(document).on('click', '.acordion-menu-left a.header-acordion', function(){
		
		if($(this).hasClass('active')){
			$(this).removeClass('active');
			$(this).parent().stop().animate({'height':'30'},300);
		}else{
			$('.acordion-menu-left a.header-acordion').parent().stop().animate({'height':'30'},300);
			$('a.header-acordion').removeClass('active');
			$(this).addClass('active');

			var alto = $(this).siblings().height();
			$(this).parent().stop().animate({'height': alto+30},300);
		}
	});


	//Scroll top
	$(document).on('click', 'a.arrow-top', function(){
    	$('html, body').stop().animate({scrollTop: $('html').offset().top},1000);
    });

	//HOME
	var owl = $('.single_owl');
	owl.owlCarousel({
		items: 1,
		loop: true,
		navigation:true,
		nav:false,
		autoplay: true,
		autoplayTimeout: 4000,
		autoplayHoverPause: true,
		autoHeight:true,
		dots: false
	});

	//GALLERY
	$(document).on('click', '.btn-contenido', function(){
		if($(this).hasClass('active')){

		}else{
			var contenido = $(this).attr('data-contenido');

			$(this).addClass('active');
			$(this).siblings().removeClass('active');
			$('.contenido').stop().animate({'opacity':'0'},300, function(){
				$(this).hide().removeClass('active');
				$('.contenido[data-contenido='+contenido+']').show().stop().animate({'opacity':'1'},300,function(){
					$(this).addClass('active');
				});
			});
		}
	});

	new WOW().init();
});